import './assets/css/App.scss';

// Router
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// Redux
import { ReduxProvider } from './redux/provider';

// Telegram
import { useWebApp } from '@vkruglikov/react-telegram-web-app';

// Layouts
import { MainLayout } from './layouts/layouts';

// Pages
import Card from './pages/card/Card';
import Wallet from './pages/wallet/Wallet';
import Welcome from './pages/welcome/Welcome';


function App() {
  // Setup TG Color
  const WebApp = useWebApp();

  WebApp.headerColor = '#007AFF';

  // Full Screen TG
  if (!WebApp.isExpanded) {
    WebApp.expand();
  }

  // Enable Closing Confirm
  WebApp.enableClosingConfirmation();

  return (
    <ReduxProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Welcome />} />
          <Route path="/home" element={<MainLayout />}>
            <Route index element={<Wallet />} />
            <Route path='card' element={<Card />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ReduxProvider>
  );
}

export default App;
