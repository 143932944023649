import style from './Navbar.module.scss';

// Router
import { Link, useLocation } from 'react-router-dom';

// Images
import cardImg from '../../assets/img/navbar/card.svg';
import cardActiveImg from '../../assets/img/navbar/card_active.svg';

import profileImg from '../../assets/img/navbar/me.svg';
import profileActiveImg from '../../assets/img/navbar/me_active.svg';

import walletImg from '../../assets/img/navbar/wallet.svg';
import walletActiveImg from '../../assets/img/navbar/wallet_active.svg';


const Navbar = () => {
    const { pathname } = useLocation();

    return (
        <div className={style.navbar}>
            <Link to={'/home/card'} className={`${style.navbar__img_block} ${pathname === '/home/card' && style.active}`}>
                <img src={pathname === '/home/card' ? cardActiveImg : cardImg} alt="card-img" />
                <p>Card</p>
            </Link>
            <Link to={'/home'} className={`${style.navbar__img_block} ${pathname === '/home' && style.active}`}>
                <img src={pathname === '/home' ? walletActiveImg : walletImg} alt="wallet-img" />
                <p>Wallet</p>
            </Link>
            <div className={`${style.navbar__img_block} ${pathname === '/home/me' && style.active}`}>
                <img src={pathname === '/home/me' ? profileActiveImg : profileImg} alt="profile-img" />
                <p>Me</p>
            </div>
        </div>
    )
}

export default Navbar;